import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import Layout from '@/components/Layout';
import ReactMarkdown from 'react-markdown';
import Helmet from 'react-helmet';
import SEO from '@/helpers/SEOHelper.js';
import Tabs from '../components/Tabs';
import ContactButton from '../components/ContactButton';
import PopupContent from '../components/PopupContent';

export const HomePageTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const whyUs = data.whyUs.edges;
  const technology = data.technology.edges;
  const solution = data.solution.edges;
  const seo = frontmatter.seo;
  const how = data.how.edges[0].node;
  const whySEM = data.whySEM.edges;
  const delivery = data.delivery.edges;
  const packagedSolutions = data.packagedSolutions.edges;

  console.log(whyUs);

  return (
    <>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo.image)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>

      <div className='min-h-screen '>
        <div className='skew-bg'></div>
        <section className='hero'>
          <div
            className='container pb-3 flex items-center justify-center lg:block'
            style={{ minHeight: '600px' }}
          >
            <div className='flex justify-center lg:justify-start  relative'>
              <div className='flex flex-col items-center lg:items-start lg:justify-between relative'>
                <ReactMarkdown
                  source={frontmatter.hero}
                  className='markdown-content max-w-2xl text-white text-center lg:text-left'
                />
                <div className='markdown-content text-white max-w-xl leading-6 text-center lg:text-left mb-8'>
                  {frontmatter.heroDescription}
                </div>
                <ContactButton lgJustify='start' text />
              </div>

              <ReactSVG
                className='hero__img absolute right-0'
                src={frontmatter.heroImage.publicURL}
              ></ReactSVG>
            </div>
          </div>
        </section>

        <section className='why mt-48'>
          <div className='container overflow-x-visible'>
            <ReactMarkdown
              source={frontmatter.why}
              className='markdown-content text-center'
            />
            <div className='card-grey__wrap '>
              {whyUs &&
                whyUs.map(({ node: whyUs }) => (
                  <div className='card-outer relative' key={whyUs.id}>
                    <div className='card-grey card-grey--s'>
                      <ReactSVG
                        className='mx-auto flex justify-center'
                        src={whyUs.frontmatter.image.publicURL}
                      />
                      <div
                        className='mt-5 mb-6 markdown-content text-black'
                        dangerouslySetInnerHTML={{ __html: whyUs.html }}
                      />
                      <PopupContent text='Learn more'>
                        <ReactMarkdown
                          source={whyUs.frontmatter.details}
                          className='mb-6 markdown-content'
                        />
                      </PopupContent>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
        <section className='mt-32 mb-32'>
          <div className='container flex flex-col lg:flex-row items-center justify-between pt-3 pb-3'>
            <div className='flex flex-col justify-center items-center lg:items-start lg:justify-start flex-1 pt-20 pb-20'>
              <h2
                className='mb-6'
                dangerouslySetInnerHTML={{ __html: how.frontmatter.title }}
              ></h2>
              <div
                className='markdown-content mb-10 text-black text-center lg:text-left leading-6'
                dangerouslySetInnerHTML={{ __html: how.html }}
              ></div>
              <ContactButton lgJustify='start' />
            </div>
            <div className='flex justify-center lg:justify-end items-center flex-1 self-stretch '>
              <div
                className='bg-black w-full h-full min-h-card max-w-card shadow-cardRed bg-cover bg-center bg-no-repeat'
                style={{
                  backgroundImage: `url(${how.frontmatter.image.publicURL})`,
                }}
              ></div>
            </div>
          </div>
        </section>
        <section className='mb-32'>
          <div className='container'>
            <h2 className='markdown-content text-center mb-16'>
              {frontmatter.howHeading}
            </h2>
            <Tabs>
              {whySEM.map((semIem) => (
                <div label={semIem.node.frontmatter.title}>
                  <div className='flex flex-col lg:flex-row justify-center items-center'>
                    <div className='flex justify-center items-center mb-20 lg:mb-0 lg:mr-40'>
                      <div className='w-64 h-64 bg-grey-light rounded-full'>
                        <img src={semIem.node.frontmatter.image.publicURL} />
                      </div>
                    </div>
                    <div>
                      <h3 className='flex flex-col justify-center items-center lg:items-start mb-7'>
                        {semIem.node.frontmatter.title}
                      </h3>
                      <div
                        className='markdown-content mb-10 text-black text-center lg:text-left leading-6'
                        dangerouslySetInnerHTML={{ __html: semIem.node.html }}
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            </Tabs>
          </div>
        </section>
        <section className='offerings pt-20 pb-32'>
          <div className='container'>
            <ReactMarkdown
              source={frontmatter.offerings}
              className='markdown-content text-center text-white '
            />
            <div className='card-dark__wrap mb-13 sm:mb-0'>
              {solution &&
                solution.map(({ node: solution }) => (
                  <div className='card-dark relative' key={solution.id}>
                    <div className='flex flex-col  h-full  pt-10 pb-10 gap-10'>
                      <div
                        className='markdown-content text-white flex flex-col  h-full'
                        dangerouslySetInnerHTML={{ __html: solution.html }}
                      />
                      <PopupContent text='Learn more'>
                        <ReactMarkdown
                          source={solution.frontmatter.details}
                          className='mb-6 markdown-content '
                        />
                      </PopupContent>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>

        <section className='mt-32 mb-32'>
          <div className='container'>
            <h2 className='markdown-content text-center mb-5'>
              {frontmatter.deliveryModesHeading}
            </h2>
            <p className='text-black text-center leading-6 mb-32 max-w-xl m-auto'>
              {frontmatter.deliveryModesParagraph}
            </p>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7'>
              {delivery.map((deliveryItem) => (
                <div className='flex flex-col items-center justify-start gap-10'>
                  <img src={deliveryItem.node.frontmatter.image.publicURL} />
                  <h5>{deliveryItem.node.frontmatter.title}</h5>
                  <div
                    className='markdown-content mb-10 text-grey-darker text-center leading-6'
                    dangerouslySetInnerHTML={{ __html: deliveryItem.node.html }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className='mt-32 mb-32'>
          <div className='container'>
            <h2 className='markdown-content text-center mb-16'>
              {frontmatter.packagedHeading}
            </h2>
            <Tabs scrollable>
              {packagedSolutions.map((packagedSolutionItem) => (
                <div
                  label={packagedSolutionItem.node.frontmatter.title}
                  key={packagedSolutionItem.node.id}
                >
                  <div className='grid grid-cols-1 lg:grid-cols-2'>
                    <div>
                      <h3 className='mb-5'>
                        {packagedSolutionItem.node.frontmatter.title}
                      </h3>
                      <div
                        className='markdown-content mb-10 text-grey-darker leading-6'
                        dangerouslySetInnerHTML={{
                          __html: packagedSolutionItem.node.html,
                        }}
                      ></div>
                    </div>
                    <div>
                      <div className='flex flex-col lg:pl-16'>
                        <div className='card-grey__wrap card-grey__wrap--wide m-0'>
                          <div className='card-outer  w-full'>
                            <div className='card-grey'>
                              <div className='flex items-center justify-start p-10'>
                                <div className='mr-6'>
                                  <svg
                                    width='36'
                                    height='36'
                                    viewBox='0 0 36 36'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M13 14.6L18 12.1L23 14.6V3H13V14.6ZM8 28V24H18V28H8ZM3 36C2.2 36 1.5 35.7 0.9 35.1C0.3 34.5 0 33.8 0 33V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H33C33.8 0 34.5 0.3 35.1 0.9C35.7 1.5 36 2.2 36 3V33C36 33.8 35.7 34.5 35.1 35.1C34.5 35.7 33.8 36 33 36H3ZM3 3V33V3ZM3 33H33V3H26V19.45L18 15.45L10 19.45V3H3V33Z'
                                      fill='#848792'
                                    />
                                  </svg>
                                </div>
                                <div className='flex flex-col items-start justify-center'>
                                  <h5 className='mb-1'>Delivered as:</h5>
                                  <p className='text-left'>
                                    {packagedSolutionItem.node.frontmatter.deliveredAs
                                      .split('or')
                                      .map((deliveredItem, index) => (
                                        <>
                                          <span className='text-red'>
                                            {deliveredItem}
                                          </span>
                                          {packagedSolutionItem.node.frontmatter.deliveredAs.split(
                                            'or',
                                          ).length -
                                            1 !==
                                            index && <span>or</span>}
                                        </>
                                      ))}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card-grey__wrap card-grey__wrap--wide m-0'>
                          <div className='card-outer  w-full'>
                            <div className='card-grey'>
                              <div className='flex items-center justify-start p-10'>
                                <div className='mr-6'>
                                  <svg
                                    width='36'
                                    height='42'
                                    viewBox='0 0 36 42'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M12 3V0H24V3H12ZM16.5 25.35H19.5V13.85H16.5V25.35ZM18 41.95C15.5333 41.95 13.2083 41.475 11.025 40.525C8.84167 39.575 6.93333 38.2833 5.3 36.65C3.66667 35.0167 2.375 33.1083 1.425 30.925C0.475 28.7417 0 26.4167 0 23.95C0 21.4833 0.475 19.1583 1.425 16.975C2.375 14.7917 3.66667 12.8833 5.3 11.25C6.93333 9.61667 8.84167 8.325 11.025 7.375C13.2083 6.425 15.5333 5.95 18 5.95C20.2333 5.95 22.3333 6.325 24.3 7.075C26.2667 7.825 28.0167 8.86667 29.55 10.2L32.1 7.65L34.2 9.75L31.65 12.3C32.85 13.6333 33.875 15.25 34.725 17.15C35.575 19.05 36 21.3167 36 23.95C36 26.4167 35.525 28.7417 34.575 30.925C33.625 33.1083 32.3333 35.0167 30.7 36.65C29.0667 38.2833 27.1583 39.575 24.975 40.525C22.7917 41.475 20.4667 41.95 18 41.95ZM18 38.95C22.1667 38.95 25.7083 37.4917 28.625 34.575C31.5417 31.6583 33 28.1167 33 23.95C33 19.7833 31.5417 16.2417 28.625 13.325C25.7083 10.4083 22.1667 8.95 18 8.95C13.8333 8.95 10.2917 10.4083 7.375 13.325C4.45833 16.2417 3 19.7833 3 23.95C3 28.1167 4.45833 31.6583 7.375 34.575C10.2917 37.4917 13.8333 38.95 18 38.95Z'
                                      fill='#848792'
                                    />
                                  </svg>
                                </div>
                                <div className='flex flex-col items-start justify-center'>
                                  <h5 className='text-left mb-1'>Duration:</h5>
                                  <p className='text-left'>
                                    {
                                      packagedSolutionItem.node.frontmatter
                                        .duration
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Tabs>
          </div>
        </section>
      </div>
    </>
  );
};

const HomePage = ({ data }) => {
  return (
    <Layout hideNav={true}>
      <HomePageTemplate data={data} />
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export const pageQuery = graphql`
  query HomePageTemplate {
    markdownRemark(frontmatter: { template: { eq: "index" } }) {
      frontmatter {
        title
        hero
        heroDescription
        heroImage {
          publicURL
        }
        why
        technology
        offerings
        howHeading
        engagementsFooter
        deliveryModesHeading
        deliveryModesParagraph
        packagedHeading
        seo {
          description
          title
        }
      }
    }
    whyUs: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "WhyUs" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            image {
              publicURL
            }
            details
          }
        }
      }
    }
    how: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "How" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            image {
              publicURL
            }
          }
        }
      }
    }
    delivery: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "delivery" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            image {
              publicURL
            }
          }
        }
      }
    }
    whySEM: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "WhySEM" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            image {
              publicURL
            }
          }
        }
      }
    }
    solution: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "Solution" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            details
          }
        }
      }
    }
    packagedSolutions: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "packagedSolutions" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            deliveredAs
            duration
            cardList
          }
        }
      }
    }
    technology: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "Technology" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            order
            image {
              childImageSharp {
                fluid(quality: 70, maxWidth: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
