import React, { useRef, useState } from 'react';
import useOnClickOutside from '../hooks/useClickOutside';
import ContactButtonAlt from './ContactButtonAlt';

const PopupContent = ({
  className,
  lgJustify = 'center',
  text = false,
  children,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const ref = useRef();
  const fullButtonClassName = 'btn btn--red btn-s ' + className;
  const fullContainerClassName =
    'flex justify-center items-center w-full lg:justify-' + lgJustify;

  useOnClickOutside(ref, () => setShowPopup(false));

  return (
    <div className={fullContainerClassName} ref={ref}>
      {showPopup && (
        <div
          className='absolute z-10 bg-grey-light p-5 mb-4 w-full max-w-2xl shadow-xl border-darkblue border'
          style={{
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {children}

          <ContactButtonAlt text='Contact us' />
        </div>
      )}
      <div onClick={() => setShowPopup((prev) => !prev)}>
        {text ? (
          <span className='text-red text-lg cursor-pointer font-semibold'>
            Learn more
          </span>
        ) : (
          <button className={fullButtonClassName}>Contact</button>
        )}
      </div>
    </div>
  );
};

export default PopupContent;
