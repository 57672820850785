import React, { useState } from 'react'

const Tabs = ({ children = [], scrollable = false }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label)
    return (
        <div className='overflow-x-hidden'>
         <div className={`flex w-full t-center border-b border-grey-border gap-8 mb-24 ${scrollable ? "overflow-scroll" : "justify-center"}`} style={{ scrollSnapType: "x mandatory"}}>
            {children.map(child=>(
                <div key={child.props.label} className={`hover:text-black pb-4 border-b-2 hover:border-red transition-all duration-150 ease-in-out cursor-pointer ${activeTab === child.props.label ? 'text-black border-red' : 'text-grey-dark border-transparent' } ${scrollable ? "whitespace-no-wrap" : ""}`} onClick={()=>setActiveTab(child.props.label)} style={{ scrollSnapAlign: "start"}} >
                    <span className='text-lg'>{child.props.label}</span>
                </div>))}
        </div>
        <div className="tab-content pl-20 pr-20">
          {children.map((child) => {
            if (child.props.label !== activeTab) return null;
            return child.props.children;
          })}
            </div>
        </div>
    )
}

export default Tabs


